.card {
  container-type: inline-size;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.card__content,
.card__footer {
  display: flex;
  padding: 16px;
}

.card__content {
  height: 100%;
}

.card__footer {
  border-top: 1px solid var(--web-2-primary-neutral-400);
}

.menu-tile__content {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    "image info"
    "cta cta";
}

.menu-tile__image-container {
  width: 72px !important;
  height: 88px !important;
  margin: 0 16px 0 0 !important;
}

.menu-tile__image {
  grid-area: image;
  cursor: pointer;
}

.menu-tile__cta {
  margin-top: 16px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  align-self: flex-end;
  grid-area: cta;
  justify-content: flex-end;
}

.menu-tile__cta-button {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  max-width: 16rem;
}

.menu-tile__cta-link {
  flex: 1;
  text-decoration: none;
  text-align: left;
  font-weight: bold;
  color: var(--primary-info-700);
}

.menu-tile__cta-link:focus {
  padding: 0.
}

.menu-tile__product-info {
  grid-area: info;
}

.menu-tile__info-icon {
  padding: 0 0 0 8px !important;
}

.menu-tile__badge {
  display: flex;
  gap: 5px;
  margin-bottom: 4px;
}

.menu-tile__product-name-crust-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@container (min-width: 600px) {
  .card__content {
    padding: 24px;
  }

  .card__footer {
    padding: 16px 24px;
  }
}

@container (min-width: 485px) {
  .menu-tile__image-container {
    width: 104px !important;
    height: 121px !important;
  }

  .menu-tile__content {
    grid-template-areas:
      "image info"
      "image cta";
  }

  .menu-tile__cta-button {
    flex: 2;
  }

  .menu-tile__cta {
    margin: 0;
    gap: 24px;
  }

  .menu-tile__cta-link {
    text-align: right;
  }
}